import {
  faMailBulk,
  faMapMarked,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Logo from "../../img/LogoWhite.svg"

export default function Footer() {
  return (
    <div className="footer container-fluid">
      <div className="container footer-grid">
        <div>
          <img src={Logo} className="logo" alt="SysDone Logo" />
        </div>
        <div className="p-white">
          <strong>
            <FontAwesomeIcon icon={faMapMarked} /> Find Us
          </strong>
          <p className="p-white">7 Adams Lane, Spring Valley NY</p>
        </div>
        <div className="p-white">
          <strong>
            <FontAwesomeIcon icon={faPhoneAlt} /> Talk To Us
          </strong>
          <p className="p-white">845-445-9172</p>
        </div>
        <div className="p-white">
          <strong>
            <FontAwesomeIcon icon={faMailBulk} /> Email Us
          </strong>
          <p className="p-white">contact@sysdone.com</p>
        </div>
      </div>
    </div>
  )
}
